@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .bg-dotted {
        background-image: radial-gradient(#fff 5%, transparent 1%);
        background-size: 30px 30px;
        background-position: 0 0, 15px 15px;
        background-repeat: repeat;
    }

    .horizontal-tb {
        writing-mode: horizontal-tb
    }

    .vertical-rl {
        writing-mode: vertical-rl
    }

    .vertical-lr {
        writing-mode: vertical-lr
    }
}

